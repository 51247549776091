import React, { useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';

interface ChatMessage {
    fromUserId: string;
    message: string;
}

const ChatView: React.FC = () => {
    const [connection, setConnection] = useState<any>(null);
    const [toUserId, setToUserId] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [receivedMessages, setReceivedMessages] = useState<ChatMessage[]>([]);
    const [currentUserId, setCurrentUserId] = useState<string>('');
    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl("https://localhost:44351/chathub")
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.on("ReceiveMessage", (fromUserId: any, receivedMessage: any) => {
                console.log(`Message received from user ${fromUserId}: ${receivedMessage}`);
                const newMessage: ChatMessage = { fromUserId, message: receivedMessage };
                setReceivedMessages(prevMessages => [...prevMessages, newMessage]);
            });

            connection.start()
                .then(() => {
                    console.log("Connection started.");
                    setCurrentUserId(connection.connectionId);
                })
                .catch((error: any) => {
                    console.error(`Error starting connection: ${error}`);
                });
        }
    }, [connection]);

    const sendMessage = () => {
        if (connection) {
            connection.invoke("SendMessage", toUserId, message)
                .catch((error:any) => {
                    console.error(`Error sending message: ${error}`);
                });
        }
    };

    return (
        <div>
            <p>Current User ID 1: {currentUserId}</p>
            <div>
                <ul>
                    {receivedMessages.map((msg, index) => (
                        <li key={index}>
                            <strong>From: {msg.fromUserId}</strong> - {msg.message}
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <input type="text" value={toUserId} onChange={e => setToUserId(e.target.value)} placeholder="To User ID" />
                <input type="text" value={message} onChange={e => setMessage(e.target.value)} placeholder="Message" />
                <button onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
};

export default ChatView;